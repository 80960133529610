import axios from "axios";
import jwt from "jsonwebtoken";
import { setTokens } from "./auth";
import { UserProfile } from "../types/profile";

export const signupAgent = (userData): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.post("agent/signup", {
				...userData,
			});
			const parsedData = jwt.decode(data?.access_token) as UserProfile;
			await setTokens(data?.access_token, data?.refresh_token);
			res(parsedData);
		} catch (error) {
			rej(error);
		}
	});
};

export const contactAgency = (contactInfo, locale = "nl") => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post("agency/contact", contactInfo, {
				params: {
					locale,
				},
			});
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const addLimitedAgncies = (limitedAgenciesData) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post("limited-agency", {
				...limitedAgenciesData,
			});
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const searchAgenciesByLatlng = (
	latLng: string,
	page: number = 1,
	limit: number = 10
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get("agency/search", {
				params: {
					latLng,
					page,
					limit,
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const searchAgenciesByLatlngFindAgent = (
	latLng: string,
	page = 1,
	limit = 4
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get("agency/find-agent-search", {
				params: {
					latLng,
					page,
					limit,
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const searchAgenciesCities = (query: string): Promise<any[]> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get("agency/search-city", {
				params: {
					query,
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const searchCitiesOnMapbox = (
	query: string,
	locale: string,
	excludePlace: boolean = false,
	excludeAddress: boolean = false
) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=be&types=${
					excludeAddress ? "" : "address%2C"
				}postcode${
					excludePlace ? "" : "%2Cplace"
				}&language=${locale}&access_token=pk.eyJ1IjoibWF0dGVvZ3JhY2VmZmEiLCJhIjoiY2txYjBiZW11MDVwcjJwbm1yMmdlaGY2eSJ9.5LiTaHbs8vlwsjwAMzm1eA`
			);

			res([
				...data.features.map((feature) => {
					let city = "";
					let zip = "";
					const type = feature?.place_type[0];
					if (type === "postcode") {
						city =
							feature?.context?.filter((el) => el.id.indexOf("place") !== -1)[0]
								?.text || "";
						zip = feature?.text;
					} else if (type === "place") {
						city = feature?.text;
					} else if (type === "address") {
						city =
							feature?.context?.filter((el) => el.id.indexOf("place") !== -1)[0]
								?.text || "";
						zip =
							feature?.context?.filter(
								(el) => el.id.indexOf("postcode") !== -1
							)[0]?.text || "";
					}
					return {
						id: feature?.id,
						center: feature?.center,
						name: feature?.place_name,
						city,
						zip,
						type,
						street: feature?.text ? feature?.text : "",
						street_number: feature?.address ? feature?.address : "",
						locality:
							feature?.context?.filter(
								(el) => el.id.indexOf("locality") !== -1
							)[0]?.text || "",
					};
				}),
			]);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyProperties = (page = 1, limit = 10): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: properties } = await axios.get("agency/properties", {
				params: {
					page,
					limit,
				},
			});
			res(properties);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyProfile = (locale): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get("agency/profile", {
				params: { locale },
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyById = (id: number, locale): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`agency/profile/${id}`, {
				params: { locale },
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyByGooglePlaceId = (
	google_place_id: string,
	locale
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`agency/by-google-place-id/${google_place_id}`,
				{
					params: { locale },
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyByGeneratedId = (
	agency_id: string,
	locale
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`agency/by-agency-id/${agency_id}`, {
				params: { locale },
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyRating = (
	agency_id: string,
	google_place_id: string,
	include_percentages: boolean = false,
	provider?: string
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`agency-reviews/rating/${google_place_id}/${agency_id}`,
				{
					params: {
						include_percentages,
						provider,
					},
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getLimitedAgencyById = (id: number): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`limited-agency/${id}`);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const createAgencyProfile = (profile) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post("agency", {
				...profile,
			});
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const updateAgencyProfile = (profile) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.put("agency", {
				...profile,
			});
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencies = (
	propertyId: number,
	page: number,
	limit: number
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: agencies } = await axios.get("agency", {
				params: { propertyId, page, limit },
			});
			res(agencies);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgenciesByLatlng = (
	latlng: string,
	zip: string,
	city: string,
	page: number,
	limit: number
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data: agencies } = await axios.get("agency", {
				params: { latlng, page, limit, include_limited: true, zip, city },
			});
			res(agencies);
		} catch (error) {
			rej(error);
		}
	});
};

export const getLatLongFromAddress = (payload): Promise<any[]> => {
	return new Promise(async (res, rej) => {
		const { searchValue, type } = payload;
		try {
			const { data } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json?country=BE&language=en&types=${type}&access_token=pk.eyJ1IjoibWF0dGVvZ3JhY2VmZmEiLCJhIjoiY2txYjBiZW11MDVwcjJwbm1yMmdlaGY2eSJ9.5LiTaHbs8vlwsjwAMzm1eA`
			);
			const listArr = [];

			const { features } = data;
			if (features.length > 0) {
				features.map((item) => {
					listArr.push({
						id: item.id,
						fullAddress: type === "place" ? item.text : item.place_name,
						location: {
							lng: item.center[0],
							lat: item.center[1],
						},
						postcode:
							item.context.filter((el) => el.id.indexOf("postcode") !== -1)[0]
								?.text || "",
						place:
							item.context.filter((el) => el.id.indexOf("place") !== -1)[0]
								?.text || "",
						region:
							item.context.filter((el) => el.id.indexOf("region") !== -1)[0]
								?.text || "",
						locality:
							item.context.filter((el) => el.id.indexOf("locality") !== -1)[0]
								?.text || "",
						street: item?.text || "",
						number: item?.address || "",
						country:
							item.context.filter((el) => el.id.indexOf("country") !== -1)[0]
								?.text || "",
					});
				});
			}
			res(listArr);
		} catch (error) {
			rej(error);
		}
	});
};

export const createAgencyProperty = (payload) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post("agency/property", { ...payload });
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const makePropertyVisible = (ids) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.patch("agency-property/property/visible", [...ids]);
			res(ids);
		} catch (error) {
			rej(error);
		}
	});
};

export const makePropertyInvisible = (ids) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.patch("agency-property/property/invisible", [...ids]);
			res(ids);
		} catch (error) {
			rej(error);
		}
	});
};

export const deleteProperties = (ids) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.delete("agency-property", {
				params: {
					ids,
				},
			});
			res(ids);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyReviewsByIB = () => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`agency-reviews/reviews/ib`);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyReviews = (
	page = 1,
	limit = 10,
	{
		rating_filters = null,
		agency_id = null,
		google_place_id = null,
	}: { agency_id?: string; google_place_id?: string; rating_filters: string }
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(`agency-reviews/mix`, {
				params: {
					page,
					limit,
					...(agency_id && { agency_id }),
					...(google_place_id && { google_place_id }),
					...(rating_filters && { rating_filters }),
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyReviewById = (review_id: string): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`agency-reviews/single-review/fetch/${review_id}`,
				{}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const createCompareAgencyLead = (
	leadData: any,
	locale: string,
	version: number = 1
) => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.post(
				"compare-agency-leads",
				{
					...leadData,
				},
				{
					params: {
						locale,
						version,
					},
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getAgencyCountByLatlng = (latLng: string) => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.get("agency/agency-search-count", {
				params: {
					latLng,
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};
