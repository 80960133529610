import axios from "axios";

export const getBlogs = () => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get("blogs");
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};
