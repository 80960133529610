import axios from "axios";

export const addNewReview = (
	reviewData: any,
	agency_id: any,
	locale: string
): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.post(
				`agency-reviews/${agency_id}`,
				{
					...reviewData,
				},
				{
					params: {
						locale,
					},
				}
			);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const sendReviewFeedbackZap = (
	reviewData: any,
	agency_id: any
): Promise<void> => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post(`agency-reviews/${agency_id}/zap`, {
				...reviewData,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};
