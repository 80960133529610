import axios from "axios";
import jwt from "jsonwebtoken";
import { setTokens } from ".";
import { UserProfile } from "../types/profile";

export const signupLead = (userData): Promise<any> => {
	return new Promise(async (res, rej) => {
		try {
			const { data } = await axios.post("lead/signup", {
				promo_mailing: false,
				...userData,
			});
			const parsedData = jwt.decode(data?.access_token) as UserProfile;
			await setTokens(data?.access_token, data?.refresh_token);
			res(parsedData);
		} catch (error) {
			rej(error);
		}
	});
};

export const getInTouch = (contactInfo: any, locale: string = "nl") => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post(
				"lead/get-in-touch",
				{
					...contactInfo,
				},
				{
					params: {
						locale,
					},
				}
			);
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const sendAgenciesRequests = (
	agency_ids: string[],
	estimation: any,
	property: any
) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post("lead/request-estimation", {
				agency_ids,
				estimation,
				property,
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const signupAds = (userData): Promise<any> => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post("lead/ads-sign-up", {
				name: userData.name,
				email: userData.email,
				phone: userData.phone,
				address: userData.address
			});
			res();
		} catch (error) {
			rej(error);
		}
	});
};
