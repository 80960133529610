import axios from "axios";

export const flanderFunnel = (
	data: any,
	locale: string,
	agencyName: string,
	version: number = 1
) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post(
				"flanders-funnel",
				{
					...data,
				},
				{
					params: { locale, agencyName, version },
				}
			);
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const homecostFunnel = (data: any, locale: string) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post(
				"homecost-funnel",
				{
					...data,
				},
				{
					params: { locale },
				}
			);
			res("");
		} catch (error) {
			rej(error);
		}
	});
};

export const initializePhoneVerification = (phone: string) => {
	return new Promise(async (res, rej) => {
		try {
			await axios.post("flanders-funnel/initialize-phone-verification", {
				phone,
			});
			res(phone);
		} catch (error) {
			rej(error);
		}
	});
};

export const verifyPhoneCode = (phone: string, code: string) => {
	return new Promise<{ status: string }>(async (res, rej) => {
		try {
			const { data } = await axios.post("flanders-funnel/verify-phone", {
				phone,
				code,
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};
