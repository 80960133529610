import axios from "axios";

export const searchaddressOnMapbox = (searchValue: string, type: string) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json?country=BE&language=en&types=${type}&access_token=pk.eyJ1IjoibWF0dGVvZ3JhY2VmZmEiLCJhIjoiY2txYjBiZW11MDVwcjJwbm1yMmdlaGY2eSJ9.5LiTaHbs8vlwsjwAMzm1eA`
			);
			const listArr = [];
			const { features } = data;
			if (features.length > 0) {
				features.map((item) => {
					listArr.push({
						id: item.id,
						fullAddress: type === "place" ? item.text : item.place_name,
						location: {
							lng: item.center[0],
							lat: item.center[1],
						},
						postcode:
							item.context.filter((el) => el.id.indexOf("postcode") !== -1)[0]
								?.text || "",
						place:
							item.context.filter((el) => el.id.indexOf("place") !== -1)[0]
								?.text || "",
						region:
							item.context.filter((el) => el.id.indexOf("region") !== -1)[0]
								?.text || "",
						locality:
							item.context.filter((el) => el.id.indexOf("locality") !== -1)[0]
								?.text || "",
						street: item?.text || "",
						number: item?.address || "",
						country:
							item.context.filter((el) => el.id.indexOf("country") !== -1)[0]
								?.text || "",
					});
				});
			}
			res(listArr.filter((item) => `${item.fullAddress}`.includes("Belgium")));
		} catch (error) {
			console.log(error);
			rej(error);
		}
	});
};
